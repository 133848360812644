body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pos-topbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px !important;
  z-index: 500;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.pos-scope {
  margin-top: 48px;
  overflow-x: hidden;
}

.modal-90w {
  width: 90% !important;
  max-width: none !important;
  height: 100%
}

.modal-80w {
  width: 80% !important;
  max-width: none !important;
  height: 100%
}

.modal-70w {
  width: 70% !important;
  max-width: none !important;
  height: 100%
}

.modal-60w {
  width: 60% !important;
  max-width: none !important;
  height: 100%
}

.green-input:focus {
  background: LightGreen;
  color: black;
}